<template>
  <div class="bg">
    <div class="container">
      <div class="message">
        <p style="color: #fff; font-size: 30px; margin-bottom: 12px">
          初识数智云控
        </p>
        <p style="color: #ccc; font-size: 23px; margin-bottom: 30px">
          Contact us
        </p>
        <p style="color: #fff; font-size: 12px; margin-bottom: 12px">
          公司地址：福建省福州市晋安区盛辉国际前横路169号
        </p>
        <p style="color: #fff; font-size: 12px; margin-bottom: 12px">
          联系方式：0591-86129167 13328650235
        </p>
        <p style="color: #fff; font-size: 12px; margin-bottom: 34px">
          电子邮箱：xiejianan@xxfqc.com
        </p>
        <div class="line"></div>
        <input v-model="name" type="text" class="inp" placeholder="您的姓名" />
        <input
          v-model.number="tel"
          type="number"
          class="inp"
          placeholder="您的联系方式"
        />
        <textarea
          type="text"
          class="inp inpb"
          v-model="problem"
          placeholder="有任何咨询或希望商讨合作的机会，请留下您的信息，我们
会尽快联系您"
        />
        <div class="sub" @click="submit">提 交</div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      name: "",
      tel: "",
      problem: "",
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    submit() {
      if (this.name != "" && this.tel != "" && this.problem != "") {
        emailjs
          .send(
            "service_gccm69f",
            // "service_t546oke",
            "template_be7rwlz",
            // "template_2oumx8p",
            { name: this.name, tel: this.tel, problem: this.problem },
            "user_n1cHNc52N465ZoBvW20Qx"
            // "user_0pRLTp5o0AxM8pgiLhqix"
          )
          .then(() => {
            // this.$toast.success("您的信息发送成功！")
            this.$toast.center("您的信息已发送成功");
            this.name = "";
            this.tel = "";
            this.problem = "";
          });
      } else {
        this.$toast.center("请留下您的信息我们，会尽快联系您");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  height: 950px;
  min-width: 1200px;
  background: url("../assets/pic/bg4.png") center no-repeat;
  background-size: 100%;
  background-color: #100f0d;
}

.message {
  float: right;
  margin-right: 100px;
  margin-top: 170px;
  width: 400px;
  height: 550px;
}

.line {
  width: 400px;
  height: 1px;
  background-color: #ccc;
  position: relative;
  left: -50px;
  margin-bottom: 30px;
}
.inp {
  width: 277px;
  height: 27px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 10px;
  margin-bottom: 10px;
  color: #ffffff;
}
.inpb {
  padding-top: 10px;
  height: 70px;
  resize: none;
  margin-bottom: 18px;
}
.sub {
  width: 60px;
  height: 27px;
  font-size: 10px;
  color: #ffffff;
  line-height: 27px;
  text-align: center;
  background: url("../assets/pic/submint.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.sub:active {
  opacity: 0.8;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
